import React, { FC } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from 'components';

const ButtonStyled = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  min-width: 15rem;
` as typeof PrimaryButton;

const Button: FC = ({ children = 'Start Now', ...props }) => (
  <ButtonStyled {...props}>{children}</ButtonStyled>
);

export default Button;
